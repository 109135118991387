/*
@color-primary-0: #FFE0AF;
@color-primary-1: #FFF7EB;
@color-primary-2: #FFEDD0;
@color-primary-3: #DEB676;
@color-primary-4: #B1853F;

@color-complement-0: #7B91AD;
@color-complement-1: #DAE1EB;
@color-complement-2: #ACBCCF;
@color-complement-3: #536F92;
@color-complement-4: #304D74;
*/

.keyboard {
  height: 100px;
  margin: 10px 0 10px 0;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
}
.keyboard-key {
  cursor: pointer;
  display: block;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: nowrap;
  border: 1px solid #000;
  padding: 5px;
  vertical-align: bottom;
  border-radius: 0;
  width: 14px;
}
.keyboard-key + .keyboard-key {
  border-left: 0;
}
.keyboard-key.black {
  border-right: 0;
  border-left: 0;
  background: #000;
  height: 60px;
  margin-right: -8px;
  margin-left: -8px;
  z-index: 1;
  width: 6px;
}
.keyboard-key.open-string {
  background: #FFDFAA;
}
.keyboard-key:active {
  background: #DAE1EB;
  outline: none;
}
.keyboard-key.active {
  background: #ACBCCF;
  outline: none;
}
.keyboard-key.black:active {
  background: #536F92;
}
.keyboard-key.black.active {
  background: #304D74;
}
.keyboard-key.open-string.active {
  background: #FFEDD0;
}

.error-message {
  color: red;
}

form {
  margin: 10px 0;
}
input, select {
  background: #fff;
  font-size: inherit;
  margin: 5px 0 5px 5px;
}
.harmonics-wrapper {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 10px;
}
.harmonics-list {
  margin: 10px 0;
}
.sounding-note {
  font-size: larger;
  margin: 20px 0 30px 0;
  text-align: center;
}

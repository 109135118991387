.harmonic-info {
  text-transform: uppercase;
}
.harmonic-info .row {
  display: flex;
  flex-flow: nowrap;
  border-bottom: 1px solid #ccc;
}
.harmonic-info .row > div {
  flex: 1 1 auto;
  padding: 5px;
}
.harmonic-info .row > div:not(:last-child) {
  border-right: 1px solid #ccc;
}
.harmonic-details {
  font-size: smaller;
  text-transform: initial;
}
.harmonic-interval {
  white-space: nowrap;
}
button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 2px 6px 3px;
  text-align: center;
}
button.active {
  background-color: #eeeeee;
}
